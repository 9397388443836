<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="670px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{ ...tooltip, ...on }"
                        @click="getDataEstrella()"
                    >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="programa.nombre"
                            label="Programa"
                            :error-messages="programaErrors"
                            @input="$v.programa.nombre.$touch()"
                            @blur="$v.programa.nombre.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            label="Descripción"
                            v-model="programa.descripcion"
                            outlined
                            counter="500"
                            :error-messages="descripcionErrors"             
                            @change="$v.programa.descripcion.$touch()"
                            @blur="$v.programa.descripcion.$touch()"
                            required>
                        </v-textarea>  
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de Ejecución"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fechaEjecucion" 
                                locale="es-CR" 
                                @input="menu = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="modificarEstrella">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        fechaMoment () {
            return this.fechaEjecucion ? moment(this.fechaEjecucion).format('DD/MM/YYYY') : '';
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        programaErrors() {
            const errors = []
            if (!this.$v.programa.nombre.$dirty) return errors            
            !this.$v.programa.nombre.required && errors.push('El nombre del programa es requerido.')
            !this.$v.programa.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descripcionErrors() {
            const errors = []
            if (!this.$v.programa.descripcion.$dirty) return errors            
            !this.$v.programa.descripcion.required && errors.push('La descripción es requerida.')
            !this.$v.programa.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
    },
    props: [
        'tipo',
        'titulo',
        'id',
    ],
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        programa: {
            nombre: '',
            descripcion: '',
        },
        fechaEjecucion: new Date().toISOString().substr(0, 10),
    }),
    validations: {
        programa: {
            nombre: { required, maxLength: maxLength(100) },
            descripcion: { required, maxLength: maxLength(100) },
        },
    },
    methods: {
        dateFormat(fecha) {
            return new Date(fecha).toISOString().substr(0, 10);
        },
        cancelar() {
            this.programa = {};
            this.fechaEjecucion = new Date().toISOString().substr(0, 10);
            this.dialog = false;
            this.loading = false;
            this.$v.programa.$reset();
        },
        getDataEstrella() {
            this.loading = true;
            axios.get("/estrellas/getProgramaGestion/"+this.id)
            .then(response => {
                this.programa = response.data.programa;   
                this.fechaEjecucion = this.dateFormat(response.data.programa.fechaEjecucion);                    
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarEstrella() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                axios.put("/estrellas/modificarProgGestRecon", this.programa, { params: {
                    idUsuario: this.idUsuario,
                    fecha: this.fechaEjecucion,
                }}).then(response => {
                    this.$emit("recargaTabla");
                    this.$store.dispatch("noti", { mensaje: "Programa modificado", color: "success" });
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al modificar programa " + error);
                    this.$store.dispatch("noti", { 
                        mensaje: "Ocurrió un error no se modificó el programa",
                        color: "error"
                    });
                    this.cancelar();
                  }
                });
            }
        }
    }
};
</script>
